import { gsap } from "gsap";
import { handleErrors } from "./application-helper";

export function handleUpload(customDate) {
    if (window.location.href.startsWith("https://www.designflows.it")) {
        console.log("Redirecting.. ");
        window.location.replace(
            window.location.href.replace(
                "https://www.designflows.it",
                "https://designflows.it"
            )
        );
    }

    const date = customDate || new Date();

    const lowerDate = new Date("Oct 8 2021 18:00:00 GMT+0100");
    const upperDate = new Date("Oct 11 2021 09:00:00 GMT+0100");
    const startWrapper = document.getElementById("start-wrapper");
    const successWrapper = document.getElementById("success-wrapper");
    const errorWrapper = document.getElementById("error-wrapper");
    const earlyWrapper = document.getElementById("early-wrapper");
    const lateWrapper = document.getElementById("late-wrapper");
    const dropArea = document.querySelector(".drop-area");
    const dots = document.querySelector(".dots");
    const uploadButton = document.getElementById("fileElem");
    const urlParams = new URLSearchParams(window.location.search);
    const applicationId = urlParams.get("applicationId");
    const token = urlParams.get("token");

    if (date >= lowerDate && date <= upperDate) {
        if (!applicationId || !token) {
            alert("This is not the correct url, check your email again.");
            throw `applicationId or token can't be nullable (${applicationId}, ${token})`;
        }

        const preventDefaults = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        const highlight = (e) => {
            dropArea.classList.add("highlight");
        };

        const unhighlight = (e) => {
            dropArea.classList.remove("highlight");
        };

        const handleDrop = (e) => {
            const dt = e.dataTransfer;
            const file = dt.files[0];

            previewFile(file);
        };

        const handleClick = (el) => {
            const file = el.target.files[0];
            previewFile(file);
        };

        const previewFile = (file) => {
            const fileSize = file.size / 1024 / 1024;
            const fileName = file.name;
            const fileExt =
                fileName.substring(
                    fileName.lastIndexOf(".") + 1,
                    fileName.length
                ) || fileName;
            dropArea.classList.add("highlight", "uploading");
            dots.classList.remove("hidden");
            dropArea.querySelector(
                ".upload-text"
            ).textContent = `Uploading ${fileName}`;
            if (fileSize > 100) {
                alert("File size exceeds 100 MB");
                uploadButton.value = "";
                dropArea.classList.remove("highlight", "uploading");
                dots.classList.add("hidden");
            } else if (fileExt != "zip") {
                alert("Please use a ZIP file");
                uploadButton.value = "";
                dropArea.classList.remove("highlight", "uploading");
                dots.classList.add("hidden");
            } else if (file) {
                gsap.to(dots.children, {
                    y: -10,
                    ease: "power1.inOut",
                    duration: 0.6,
                    stagger: 0.2,
                    repeat: -1,
                    yoyo: true,
                });
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    uploadFile(file);
                    // successAnimation();
                };
            }
        };

        const staggerAnimation = (secondWrapper) => {
            const desktopWrapper = document.getElementById("desktop-wrapper");
            const tl = gsap.timeline();

            tl.to(desktopWrapper.children, {
                opacity: 0,
                y: -30,
                ease: "power1.inOut",
                duration: 0.6,
                stagger: 0.2,
                onComplete: () => {
                    startWrapper.classList.add("hidden");
                    secondWrapper.classList.remove("hidden");
                    secondWrapper.scrollIntoView();
                },
            });
            tl.fromTo(
                secondWrapper.children,
                {
                    opacity: 0,
                    y: 40,
                },
                {
                    opacity: 1,
                    y: 0,
                    ease: "power1.inOut",
                    duration: 0.6,
                    stagger: 0.2,
                    delay: 0.2,
                }
            );
        };

        const successAnimation = () => {
            setTimeout(() => {
                staggerAnimation(successWrapper);
            }, 4000);
        };

        const errorAnimation = (error) => {
            staggerAnimation(errorWrapper);
        };

        const uploadFile = (file) => {
            var url = `https://rolemodel.bendingspoons.com/events/${applicationId}/presign`;
            fetch(url, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: token,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    console.log(response);
                    const uploadUrl = response.url;
                    const formData = new FormData();
                    formData.append("file", file);

                    fetch(uploadUrl, {
                        method: "put",
                        headers: {
                            "Content-type": file.type,
                        },
                        body: formData,
                    })
                        .then(handleErrors)
                        .then((data) => {
                            successAnimation();
                        })
                        .catch((error) => {
                            errorAnimation(error);
                        });
                })
                .catch((error) => {
                    errorAnimation(error);
                    console.log(`error:${error}`);
                });
        };

        // Prevent default drag behaviors
        ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
            dropArea.addEventListener(eventName, preventDefaults, false);
            document.body.addEventListener(eventName, preventDefaults, false);
        });

        // Highlight drop area when item is dragged over it
        ["dragenter", "dragover"].forEach((eventName) => {
            dropArea.addEventListener(eventName, highlight, false);
        });
        ["dragleave", "drop"].forEach((eventName) => {
            dropArea.addEventListener(eventName, unhighlight, false);
        });

        // Handle dropped files
        dropArea.addEventListener("drop", handleDrop, false);
        uploadButton.addEventListener("change", handleClick, false);
    } else {
        startWrapper.classList.add("hidden");
        if (date < lowerDate) {
            earlyWrapper.classList.remove("hidden");
        } else {
            lateWrapper.classList.remove("hidden");
        }
    }
}
