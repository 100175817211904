import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import * as animationData from "../assets/videos/animation.json";

gsap.registerPlugin(ScrollTrigger);

export function handleAccordion() {
    const acc = document.querySelectorAll(".accordion");

    acc.forEach((el) => {
        el.addEventListener("click", () => {
            el.classList.toggle("active");
            let panel = el.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                panel.style.opacity = "0";
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
                panel.style.opacity = "1";
            }
        });
    });
}

export const marqueeHelper = () => {
    const marquee = document.getElementById("hall_of_fame");
    const marqueeRows = document.querySelectorAll(".marquee__inner");

    ScrollTrigger.create({
        trigger: marquee,
        start: "top 100%",
        end: "110% 0%",
        onEnter: () => {
            marqueeRows.forEach((el) => {
                el.style.webkitAnimationPlayState = "running";
            });
        },
        onEnterBack: () => {
            marqueeRows.forEach((el) => {
                el.style.webkitAnimationPlayState = "running";
            });
        },
        onLeave: () => {
            marqueeRows.forEach((el) => {
                el.style.webkitAnimationPlayState = "paused";
            });
        },
        onLeaveBack: () => {
            marqueeRows.forEach((el) => {
                el.style.webkitAnimationPlayState = "paused";
            });
        },
    });
};

export const navBarAnimation = () => {
    const navbar = document.querySelector(".navbar__wrapper");
    const header = document.querySelector(".header");
    const button = document.querySelector(".button-apply");
    const buttonDate = document.querySelector(".button-apply__date");
    gsap.from(navbar, {
        y: -100,
        autoAlpha: 0,
        ease: "power2",
        duration: 0.4,
        scrollTrigger: {
            trigger: header,
            toggleActions: "play none reverse reverse",
            start: "bottom 10%",
        },
    });

    ScrollTrigger.create({
        trigger: button,
        start: "top 0%",
        end: "top 0%",
        onEnter: () => {
            button.classList.add("fixed-top");
            buttonDate.classList.add("hidden");
            buttonDate.style.webkitAnimationPlayState = "paused";
        },
        onEnterBack: () => {
            button.classList.remove("fixed-top");
            buttonDate.classList.remove("hidden");
            buttonDate.style.webkitAnimationPlayState = "running";
        },
    });
};

export const lineReveal = () => {
    const bigLetters = document.querySelectorAll(".line-reveal");

    bigLetters.forEach((el) => {
        const lines = el.querySelectorAll(".line span");
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: "top 70%",
            },
        });
        tl.set(bigLetters, { autoAlpha: 1 });
        tl.from(lines, {
            yPercent: 100,
            duration: 0.5,
            ease: "power2.out",
            stagger: 0.1,
            delay: 0.2,
        });
    });
};

export const imageBlockReveal = () => {
    const animatedImage = document.querySelectorAll(".image-container");

    animatedImage.forEach((el) => {
        const image = el.querySelector("img");
        const revealer = el.querySelector(".revealer");

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: "top 70%",
            },
        });

        tl.to(revealer, {
            duration: 0.8,
            width: "0%",
            ease: "power2.inOut",
        }).from(image, {
            duration: 0.8,
            scale: 1.3,
            ease: "power2.inOut",
            delay: -1,
        });
    });
};

export const perkCardReveal = () => {
    const oddCards = document.querySelectorAll(".perk-card:nth-child(odd)");
    const evenCards = document.querySelectorAll(".perk-card:nth-child(even)");

    const cardReveal = (el, reverse) => {
        gsap.from(el, {
            translateX: reverse ? -300 : 300,
            translateY: 400,
            rotate: reverse ? -30 : 30,
            duration: 0.4,
            scrollTrigger: {
                trigger: el,
                scrub: 1,
                start: "-50px 100%",
                end: "-50px 70%",
            },
        });
    };
    evenCards.forEach((el) => {
        cardReveal(el, true);
    });
    oddCards.forEach((el) => {
        cardReveal(el, false);
    });
};

export function createPreloader() {
    document.documentElement.style.overflowY = "hidden";
    const preloader = document.querySelector(".preloader");
    const preloaderLogo = document.querySelector(".preloader__logo");
    const tl = gsap.timeline();
    const text = document.querySelectorAll(".hero-reveal");
    const lines = document.querySelectorAll(".hero-reveal .line span");
    lottie.loadAnimation({
        container: preloaderLogo, // the dom element that will contain the animation
        renderer: "svg",
        autoplay: true,
        loop: false,
        animationData: animationData, // the path to the animation json
    });
    tl.to(preloaderLogo, {
        translateY: "-200%",
        duration: 1.2,
        autoAlpha: 0,
        delay: 2,
        ease: "power4.inOut",
    });
    tl.to(
        preloader,
        {
            translateY: "-100%",
            duration: 1,
            ease: "power4.inOut",
            onComplete: () => {
                document.documentElement.style.overflowY = "scroll";
                preloader.classList.add("hidden");
            },
        },
        "-=0.9"
    );
    tl.set(text, { autoAlpha: 1 });
    tl.from(
        lines,
        {
            yPercent: 100,
            duration: 0.5,
            ease: "power2.out",
            stagger: 0.1,
            delay: 0.2,
        },
        "-=0.6"
    );
}
