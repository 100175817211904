import { gsap } from "gsap";

const BASE_URL = "https://rolemodel.bendingspoons.com";
const EVENT_ID = "5ddfd3c02f0e3193d8c33ee5";
const ROLE_ID = "60d1e7524a0938948a716c1d";
const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
};

const fetchDeadline = async (testingDate) => {
    const deadlineText = document.querySelectorAll(".deadline-text");
    const todayDate = testingDate ? new Date(testingDate) : new Date();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const testing = urlParams.get("testing");
    const applicationButton = document.querySelector(".button-apply");
    const applicationButtonText = document.querySelector(".button-apply__text");
    let applicationYetToStart = false;
    let applicationOver = false;
    let applicationOpen = false;

    let shouldShowReminder = await fetch(`${BASE_URL}/events/${EVENT_ID}`, {
        method: "GET",
    })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => {
            const applicationDeadline = new Date(data.close_at);
            const applicationOpening = new Date(data.open_at);
            applicationYetToStart = applicationOpening > todayDate;
            applicationOver = applicationDeadline < todayDate;
            applicationOpen = data.are_applications_open;
            if (!applicationOpen && !testing && applicationYetToStart) {
                applicationButtonText.innerHTML = "Get ready!<br />Coming Soon";
                applicationButton.classList.add("disabled");
                applicationButton.disabled = true;
                deadlineText.forEach((el) => {
                    el.textContent = `Applications open ${
                        MONTH_NAMES[applicationOpening.getUTCMonth()]
                    } ${applicationOpening.getUTCDate()}, ${applicationOpening.getUTCFullYear()}`;
                });
                return true;
            }
            if (applicationOpen || testing) {
                deadlineText.forEach((el) => {
                    el.textContent = `Apply by ${
                        MONTH_NAMES[applicationDeadline.getUTCMonth()]
                    } ${applicationDeadline.getUTCDate()}, ${applicationDeadline.getUTCFullYear()}`;
                });
                return false;
            }
            if (!applicationOpen && !testing && applicationOver) {
                applicationButtonText.textContent = "Applications Closed";
                applicationButton.classList.add("disabled");
                applicationButton.disabled = true;
                deadlineText.forEach((el) => {
                    el.textContent = "This edition is over. See you next year!";
                });
                return false;
            }
        })
        .catch((error) => {
            console.log("Error:", error);
            return false;
        });
    return shouldShowReminder;
};

export const handleApplication = async () => {
    const shouldShowReminder = await fetchDeadline();
    const modalContent = document.querySelector(".modal__content");
    const modal = document.getElementById("application_modal");
    const applicationContent = document.getElementById("application_content");
    const applicationInner = document.getElementById("application_inner");
    const successContent = document.getElementById("success_content");
    const successCloseButton = document.querySelector(
        ".modal__apply-button__success"
    );
    const successInner = document.getElementById("success_inner");
    const applyMain = document.getElementById("apply_button");
    const closeButton = document.querySelector(".modal__close");

    const validateEmail = (email) => {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validateInput = (firstname, lastname, email) => {
        if (firstname.replace(/\s/g, "").length === 0) {
            return "Please specify a valid first name.";
        }
        if (lastname.replace(/\s/g, "").length === 0) {
            return "Please specify a valid last name.";
        }
        if (email == "" || !validateEmail(email)) {
            return "Please specify a valid email.";
        }

        return false;
    };

    const allFilled = () => {
        let filled = false;

        if (
            document.getElementById("privacy").checked &&
            document.getElementById("european").checked
        ) {
            filled = true;
        }
        applicationContent
            .querySelectorAll("input.required")
            .forEach((inputEl) => {
                if (!inputEl.value) filled = false;
            });
        return filled;
    };

    const validateForm = () => {
        if (allFilled()) {
            applyMain.disabled = false;
            applyMain.classList.remove("disabled");
        } else {
            applyMain.disabled = true;
            applyMain.classList.add("disabled");
        }
    };

    const saveTracking = () => {
        // calculate the query string
        var qs = (function (a) {
            if (a == "") return {};
            var b = {};
            for (var i = 0; i < a.length; ++i) {
                var p = a[i].split("=", 2);
                if (p.length == 1) b[p[0]] = "";
                else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
            return b;
        })(window.location.search.substr(1).split("&"));

        if (qs && window.localStorage) {
            var utm_source =
                qs.utm_source || localStorage.getItem("utm_source") || "";
            var utm_medium =
                qs.utm_medium || localStorage.getItem("utm_medium") || "";
            var utm_term =
                qs.utm_term || localStorage.getItem("utm_term") || "";
            var utm_content =
                qs.utm_content || localStorage.getItem("utm_content") || "";
            var utm_campaign =
                qs.utm_campaign || localStorage.getItem("utm_campaign") || "";

            localStorage.setItem("utm_source", utm_source);
            localStorage.setItem("utm_medium", utm_medium);
            localStorage.setItem("utm_term", utm_term);
            localStorage.setItem("utm_content", utm_content);
            localStorage.setItem("utm_campaign", utm_campaign);
        }
    };

    const successForm = (modalType) => {
        const tl = gsap.timeline({ ease: "power2.out" });
        tl.to(closeButton, {
            translateY: -20,
            autoAlpha: 0,
            duration: 0.1,
        });
        tl.set(applyMain, { left: "auto" });
        tl.to(applyMain, {
            right: 32,
            rotate: 372,
            duration: 0.6,
        });
        tl.to(applicationInner.children, {
            translateY: -30,
            autoAlpha: 0,
            duration: 0.4,
            stagger: 0.08,
            onComplete: () => {
                applicationInner.classList.add("hidden");
                successInner.classList.remove("hidden");
            },
        });
        tl.from(successInner.children, {
            translateY: 50,
            autoAlpha: 0,
            duration: 0.6,
            delay: 0.2,
            stagger: 0.1,
        });
        tl.to(applyMain, {
            color: "#5D43FF",
            duration: 0.1,
        });
        tl.from(successCloseButton, {
            autoAlpha: 0,
            duration: 0.2,
            onComplete: () => {
                applyMain.classList.add("hidden");
            },
        });
    };

    const errorForm = (errors, modal) => {
        const errorMessage = modal.querySelector(".error-text");
        const errorMessageText = modal.querySelector(".error-text p");
        if (errors) {
            errorMessage.classList.add("flex");
            errorMessageText.textContent = errors
                ? errors
                : "An error has occured, please try again.";
        }
    };

    const sendForm = () => {
        saveTracking();
        let formdata = new FormData();

        // add basic info
        const first_name = document.getElementById("name").value;
        const last_name = document.getElementById("surname").value;
        const email = document.getElementById("email").value;
        const portfolio_url = document.getElementById("portfolio").value;
        const nationality = document.getElementById("nationality").value;

        const errors = validateInput(first_name, last_name, email);
        const applicant_data = {
            "57588b958985f10010fe5e14": first_name,
            "57588b9e8985f10010fe5e15": last_name,
            "57588bac8985f1000e166a34": email,
            "582c949a186cf40010023174": portfolio_url,
            "60e85baaf5bba69419f4d8dd": nationality,
            utm_source: localStorage.getItem("utm_source") || "",
            utm_medium: localStorage.getItem("utm_medium") || "",
            utm_term: localStorage.getItem("utm_term") || "",
            utm_content: localStorage.getItem("utm_content") || "",
            utm_campaign: localStorage.getItem("utm_campaign") || "",
        };
        const campaign_number = localStorage.getItem("utm_campaign") || "";

        formdata.append("applicant_data", JSON.stringify(applicant_data));

        if (campaign_number) {
            formdata.append("campaign_number", campaign_number);
        }

        applyMain.disabled = true;
        if (errors) {
            console.log(errors);
            errorForm(errors, applicationContent);
            applyMain.disabled = false;
        } else {
            fetch(`${BASE_URL}/roles/${ROLE_ID}`, {
                method: "POST",
                body: formdata,
            })
                .then(handleErrors)
                .then((data) => {
                    successForm(applicationContent);
                })
                .catch((error) => {
                    errorForm(error, applicationContent);
                });
        }
    };

    applicationContent.querySelectorAll("input.required").forEach((inputEl) => {
        inputEl.addEventListener("keyup", validateForm);
    });

    applicationContent
        .querySelectorAll(".styled-checkbox")
        .forEach((inputEl) => {
            inputEl.addEventListener("change", validateForm);
        });
    applyMain.addEventListener("click", sendForm);
};
