export class MarqueeImg {
    constructor(el) {
        this.DOM = { el: el };
        this.DOM.images = this.DOM.el.querySelectorAll(".marquee__img");

        this.applyBackground();
    }

    applyBackground() {
        [...this.DOM.images].map(
            (image) =>
                (image.style.backgroundImage = `url(${this.DOM.el.dataset.img})`)
        );
    }
}
