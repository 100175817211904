import "./styles/index.css";
// import { handleCookies } from "./scripts/cookies-helper";
import { cssVarSupport } from "./scripts/browser-helper";
import { handleModal } from "./scripts/modal-helper";
import { handleApplication } from "./scripts/application-helper";
import {
    handleAccordion,
    navBarAnimation,
    lineReveal,
    imageBlockReveal,
    perkCardReveal,
    createPreloader,
    marqueeHelper,
} from "./scripts/animation-helper";

import { customCursor } from "./scripts/cursor-animation";
import { HoverEffect } from "./scripts/hover-effect";
import { MarqueeImg } from "./scripts/marqueeImages-helper";
import { handleUpload } from "./scripts/upload-helper";

const isUpload = window.location.href.indexOf("upload");
cssVarSupport();

createPreloader();
customCursor();
if (isUpload > -1) {
    // debug variable REMOVE AFTER TEST
    // let customDate = new Date("Oct 9 2021 19:00:00 GMT+0100");
    handleUpload();
} else {
    document.addEventListener("DOMContentLoaded", () => {
        handleApplication();
        handleAccordion();
        // handleCookies();
        handleModal();
        navBarAnimation();
        lineReveal();
        marqueeHelper();
        imageBlockReveal();
        perkCardReveal();
    });
}
window.onbeforeunload = () => {
    window.scrollTo(0, 0);
};

[...document.querySelectorAll(".winner-row")].forEach((row) => {
    new HoverEffect(row);
    new MarqueeImg(row);
});
