const showModal = (modal) => {
    document.documentElement.style.overflowY = "hidden";
    modal.classList.add("is-active");
};

const removeModal = (modal) => {
    modal.classList.remove("is-active");
    document.documentElement.style.overflowY = "scroll";
};

export const handleModal = () => {
    const overlay = document.querySelector(".modal-overlay"),
        modal = document.querySelector("#application_modal"),
        closeButton = modal.querySelector(".modal__close"),
        closeButtonSuccess = modal.querySelector(
            ".modal__apply-button__success"
        );
    let modalOpen = false;

    const removeModalHandler = () => {
        removeModal(modal);
        modalOpen = false;
    };

    [].slice.call(document.querySelectorAll(".button-apply")).forEach((el) => {
        el.addEventListener("click", (ev) => {
            ev.stopPropagation();
            showModal(modal);
            overlay.removeEventListener("click", removeModalHandler);
            overlay.addEventListener("click", removeModalHandler);
            modalOpen = true;
        });

        closeButton.addEventListener("click", (ev) => {
            ev.stopPropagation();
            removeModal(modal);
        });

        closeButtonSuccess.addEventListener("click", (ev) => {
            ev.stopPropagation();
            removeModal(modal);
        });
    });
};
