import gsap from "gsap";

/**
 * Utility functions
 */

const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        posx =
            e.clientX +
            document.body.scrollLeft +
            document.documentElement.scrollLeft;
        posy =
            e.clientY +
            document.body.scrollTop +
            document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
};

export class HoverEffect {
    constructor(el) {
        this.DOM = { el: el };
        this.DOM.reveal = document.createElement("div");
        this.DOM.reveal.className = "hover-reveal";
        this.DOM.reveal.innerHTML = `<div class="hover-reveal__inner"><div class="hover-reveal__img" style="background-image:url(${this.DOM.el.dataset.img})"></div></div>`;
        this.DOM.el.appendChild(this.DOM.reveal);
        this.DOM.revealInner = this.DOM.reveal.querySelector(
            ".hover-reveal__inner"
        );
        this.DOM.revealInner.style.overflow = "hidden";
        this.DOM.revealImg =
            this.DOM.revealInner.querySelector(".hover-reveal__img");

        this.initEvents();
    }

    initEvents() {
        this.positionElement = (ev) => {
            const mousePos = getMousePos(ev);
            const docScrolls = {
                left:
                    document.body.scrollLeft +
                    document.documentElement.scrollLeft,
                top:
                    document.body.scrollTop +
                    document.documentElement.scrollTop,
            };
            this.DOM.reveal.style.top = `${mousePos.y + 20 - docScrolls.top}px`;
            this.DOM.reveal.style.left = `${
                mousePos.x + 20 - docScrolls.left
            }px`;
        };

        this.mouseenterFn = (ev) => {
            this.positionElement(ev);
            this.showImage();
        };

        this.mousemoveFn = (ev) =>
            requestAnimationFrame(() => {
                this.positionElement(ev);
            });

        this.mouseleaveFn = () => {
            this.hideImage();
        };

        this.DOM.el.addEventListener("mouseenter", this.mouseenterFn, {
            passive: true,
        });
        this.DOM.el.addEventListener("mousemove", this.mousemoveFn, {
            passive: true,
        });
        this.DOM.el.addEventListener("mouseleave", this.mouseleaveFn, {
            passive: true,
        });
    }

    showImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImg);

        this.tl = gsap
            .timeline({
                onStart: () => {
                    this.DOM.reveal.style.opacity = 1;
                    gsap.set(this.DOM.el, { zIndex: 30 });
                },
            })
            .add("begin")
            .add(
                gsap.to(this.DOM.revealInner, {
                    duration: 0.4,
                    ease: "sine.out",
                    startAt: { x: "-100%" },
                    x: "0%",
                }),
                "begin"
            )
            .add(
                gsap.to(this.DOM.revealImg, {
                    duration: 0.4,
                    ease: "sine.out",
                    startAt: { x: "100%" },
                    x: "0%",
                }),
                "begin"
            );
    }

    hideImage() {
        gsap.killTweensOf(this.DOM.revealInner);
        gsap.killTweensOf(this.DOM.revealImg);

        this.tl = gsap
            .timeline({
                onStart: () => {
                    gsap.set(this.DOM.el, { zIndex: 29 });
                },
                onComplete: () => {
                    gsap.set(this.DOM.el, { zIndex: "" });
                    gsap.set(this.DOM.reveal, { opacity: 0 });
                },
            })
            .add("begin")
            .add(
                gsap.to(this.DOM.revealInner, {
                    duration: 0.4,
                    ease: "sine.out",
                    x: "100%",
                }),
                "begin"
            )

            .add(
                gsap.to(this.DOM.revealImg, {
                    duration: 0.4,
                    ease: "sine.out",
                    x: "-100%",
                }),
                "begin"
            );
    }
}
